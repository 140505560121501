import React, { useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {StaticImage, GatsbyImage} from "gatsby-plugin-image"
import {SectionHero, Section, SectionTitle, SectionDescription, SectionPageTitle, SectionImageBkg, ListPointerBlue, ListItemPointerBlue } from "../components/Section"
import { CrewIcon, FailedInstallationIcon, LineArrowDownIcon, MechanicalIcon, WeatherIcon  } from "../components/Icons"
import SearchBar from "../components/SearchBar"

const SectionHeros = styled(SectionHero)`
	position:relative;
    height: 100%;
    &:after, &:before{
        display:block;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
    }
	&:after{
		width:60%;
		background-color: rgba(255,255,255,0.20);		
        clip-path: ellipse(25% 60% at 0 50%);
	}    
	&:before{        
		width:40%;
        background: linear-gradient(270deg, #1383C1 0%, #006EAC 100%);			
        clip-path: ellipse(25% 52% at 0 50%);
		z-index:1;
        opacity:0.8;
	}
	
`
const HeroBanner = styled.div`	
	position: relative;
    background: #1383c1;
    background: linear-gradient(to bottom,  #1383c1 0%, #074a6f 100%);
    @media(max-width:767.98px){
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
    }
    .gatsby-image-wrapper {
        opacity:0.1;
        height:100%;
        @media(min-width:1200px){
            height: calc(100vh - 80px);
        }
    }

    
`

const HeroCaption = styled.div`	
    @media (min-width:768px){
        position:absolute;
        top:0;
        left:0;
    }
    width: 100%;
    padding:40px 0;
    @media (min-width:992px){
        padding:60px 0;
    }
    @media (min-width:1200px){
        padding:80px 0;
    }
    @media (min-width:1600px){
        padding:120px 0;
    }
    .container{
        margin:0 auto;
        max-width:1080px;
        @media (min-width: 1440px) {
            max-width:1100px;
        }
        @media (min-width: 1600px) {
            max-width:1360px;
        }
    }
	
`
const HeroSearch = styled.div`
    @media (min-width:992px){
        min-height: 78px;
        margin-top:-78px;
    }
    @media (min-width:1600px){
        min-height:100px;
        margin-top:-100px;
    }
    .search-bar{
        @media (min-width:992px){
            border-radius: 15px 15px 0 0;
        }
        @media(min-width:1200px){
            border-radius: 20px 20px 0 0;
        }
    }
    .container{
        @media (max-width:991.98px){
            padding:0;
        }
    }
`

const HeroCaptionGrid = styled.div`
	display:flex;
    flex-wrap:wrap;
    align-items:center;
    justify-content:center;
    max-width:695px;
    width:100%;
    margin:0 auto;
    position: relative;
    z-index: 1;
`
const HeroCaptionItem = styled.div`
    position:relative;
    width:100%;
`

const SectionGrid = styled.div`
    position: relative;
    margin-bottom:30px;
    @media(min-width:768px){
        counter-reset:section;
    }
`
const SectionGridItem = styled.div`
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    position:relative;
    justify-content:center;
    margin:30px -15px 0 -15px;
    flex-direction: column-reverse;
    @media(min-width:768px){
        margin:0 -20px;
        flex-direction: row;
    }
    @media(min-width:1200px){
        margin:0 -25px;
    }
    &:before {
        @media(min-width:768px){
            content: counter(section);
            counter-increment: section;
            font-weight: 700;
            font-family: 'Teko',sans-serif;
            position: absolute;
            left:15px;
            top: 50%;
            transform: translateY(-50%);
            color: rgba(19,131,193,0.06);
            font-size: 130px;
            line-height: 90px;
        }
        @media(min-width:992px){
            left:30px;
            font-size: 230px;
            line-height: 190px;
        }
    }
`
const ProcessContent = styled.div`
    position:relative;
    width:100%;
    display: flex;
    align-items:center;
    padding:0 15px;
    @media(min-width:768px){
        padding:0 20px;
        max-width:58.333333%;
        flex:0 0 58.333333%;
        min-height:360px;
    }
    @media(min-width:1200px){
        padding:0 25px;
        min-height:480px;
        max-width:41.666667%;
        flex:0 0 41.666667%;
    }
`
const ProcessThumbnail = styled.div`
    position:relative;
    width:100%;
    text-align:center;
    padding:0 15px;
    padding:0 15px;
    @media(min-width:768px){
        padding:0 20px;
        max-width:33.333333%;
        flex:0 0 33.333333%;
    }
    @media(min-width:1200px){
        padding:0 25px;
    }
`
const ProcessText = styled.div`
    margin:0;
`
const ThumbnailFigure = styled.div`
    display:inline-flex;
    align-items:center;
    justify-content:center;
    position:relative;
    .gatsby-image-wrapper{
        border-radius:50%;
        position:relative;
        z-index:1;
    }
    &:after, &:before{
        content:'';
        position:absolute;
        border-radius:50%;
        top:50%;
        left:50%;
        transform:translate(-50%, -50%) ;
    }
    &:before{
        background-color:rgba(19,131,193,0.05);
        width:120%;
        height:120%;
        @media(min-width:1200px){
            width:140%;
            height:140%;
        }
        @media(min-width:1600px){
            width:160%;
            height:160%;
        }
    }
    &:after{
        background-color:rgba(19,131,193,0.05);
        width:110%;
        height:110%;
        @media(min-width:1200px){
            width:120%;
            height:120%;
        }
        @media(min-width:1600px){
            width:130%;
            height:130%;
        }
    }  
    
`
const SetionDotted = styled.div`
    position: relative;
    border: 2px dashed rgba(112,112,112,0.6);
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    border-radius: 20px;
    padding: 30px;
    @media(min-width:1200px){
        padding: 40px;
    }
    @media(min-width:1600px){
        padding: 60px;
    }
    &:before {
        content: '';
        position: absolute;
        top: -16px;
        left: 60px;
        display: inline-block;
        border-top: 2px dashed rgba(112,112,112,0.6);
        border-right: 2px dashed rgba(112,112,112,0.6);
        width: 30px;
        height: 30px;
        transform: rotate(-45deg);
        background: #fff;
    }
    > ul{
        margin-bottom:0;
    }
    > p {
        color:#999;
    }
`

const CardGrid = styled.div`
    max-width:1210px;
    width:100%;
    margin:0 auto;    
    `
const CardGridItem = styled.div`
    &.card-grid-item{
        + .card-grid-item{
            margin-top:20px;
        }
    }
`
const CardIcon = styled.div`
   background-color:#fff;
   border-radius:20px;
   box-shadow:0 0 99px rgba(0,0,0,0.16);
   padding:30px 20px;
   display:flex;
   align-items:center;   
   
   @media (min-width: 768px) {
    padding:30px;
   }
   @media (min-width: 992px) {
    padding:30px 60px;
   }
    
`
const CardIconTitle = styled.strong`
    display:block;
    margin-bottom:10px;
    font-size: 20px;
    line-height: 28px;
    @media (min-width: 1200px) {
        font-size: 30px;
        line-height: 30px;
    }
   
`
const CardIconBody = styled.div`
    display:flex;
    flex-wrap:wrap;
    margin:0 -15px;
    @media (min-width: 992px) {
        margin:0 -25px;
    }
`
const CardIconFigure = styled.div`
    position:relative;
    width:100%;    
    display:flex;
    align-items:center;
    padding:0 15px 15px 15px;
    @media (min-width: 768px) {
        padding:0 15px;

    }
    strong{
        margin:0 0 0 15px;
        @media (min-width: 768px) {
            display:none;
        }
   }
    @media (min-width: 768px) {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    @media (min-width: 992px) {
        padding:0 25px;
    }
`
const CardIconContent = styled.div`
    position:relative;
    width:100%;
    padding:0 15px;
    strong{
        display:none;
    }
    @media (min-width: 768px) {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        strong{
            display:block;
        }
    }
    @media (min-width: 992px) {
        padding:0 25px;
    }
    p{
        color:#666;
        margin-bottom:0
    }

`

const ProcessStep = styled.div`
    .gatsby-image-wrapper{
        display:inline-block;
    }
`
const ProcessStrip = styled.div`
    position:relative;
    max-width: 680px;
    margin: 0 auto;
    @media(min-width:768px){
        margin: -15% auto 0 auto;
    }

`
const ProcessStartPoint = styled.div`
    font-size: 12px;
    line-height: 18px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    position: absolute;
    top: -10px;
    left: -5px;
    @media(min-width:768px){
        font-size: 14px;
        top: -8px;
        left: -20px;
    }
`
const ProcessLabel = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -20px;
    @media(min-width:768px){
    margin-top:-40px;
    }
`
const ProcessItemLabel = styled.div`
    font-weight:700;    
    text-transform:uppercase;
    cursor:pointer;
    position:relative;   
    text-align:center; 
    font-size:14px;
    line-height:28px;    
    @media(min-width:768px){
        font-size:20px;
        line-height:30px;
    }
    @media(min-width:1440px){
        font-size:26px;
        line-height:36px;
    }
    & .process-point{

        & .circle{
            display:inline-block;
            position:relative;
            width:18px;
            height:18px;
            @media(min-width:768px){
                width:40px;
                height:40px;
            }
            text-align:center;
            &:after, &:before{
                display:block;
                position:absolute;
                content:'';
                border-radius:50%;
            }
            &:after{
                width:6px;
                height:6px;
                @media(min-width:768px){
                    width:14px;
                    height:14px;
                }
                background:#FDA036;
                top:50%;
                left:50%;
                transform:translate(-50%, -50%);
            }
            &:before{
                width:100%;
                height:100%;
                background:#0D496B;
                border:4px solid #FDA036;
                @media(min-width:768px){
                    border-width:8px;
                }
            }
        }
        & .text{
            display:block;
            color:#fff;
            transition: all 0.5s ease;
            .icon{
                margin-left:3px;
                @media(max-width:767.98px){
                    display:block;
                    text-align: center;

                    svg {
                        height: 15px;
                    }
                }
                > svg{
                    fill:#fff;
                }
            }
            &:hover{
                color:#E08932;
                .icon{
                    > svg{
                        fill:#E08932;
                    }
                }
            }
        }

    }
    &:nth-child(1){
        text-align:right;
        top: -40px;
        left: 0;
        @media(min-width:768px){
            top: -65px;
            left: 30px;
        }
        @media(min-width:1440px){
            left: 5px;
        }
        & .process-point{
            & .circle{
                &:after{background:#FDA036;}
                &:before{border-color:#FDA036;}
            }
        }
    }
    &:nth-child(2){
        & .process-point{
            & .circle{
                &:after{background:#27E3E3;}
                &:before{border-color:#27E3E3;}
            }
        }
    }
    &:nth-child(3){
        & .process-point{
            & .circle{
                &:after{background:#F8DA3C;}
                &:before{border-color:#F8DA3C;}
            }
        }
    }
    &:nth-child(4){
        text-align:left;
        top: -30px;
        left: -15px;
        @media(min-width:768px){
            top: -60px;
            left: -40px;
        }
        @media(min-width:1440px){
            left: -20px;
        }
        & .process-point{
            & .circle{
                &:after{background:#40C106;}
                &:before{border-color:#40C106;}
            }
        }
    }
`
const SectionPageTitles = styled(SectionPageTitle)`
    @media(min-width:1200px){
        max-width:275px;
    }
`
const SectionDescriptions = styled(SectionDescription)`
    max-width:495px;
    @media(min-width:1440px){
        max-width:480px;
    }
`

const Orderprocess = ({ location, data }) => {
    useEffect(() => {
       document.addEventListener('click', function(event) {
            if (!event.target.matches('.btn-scroll-into')) return;
            event.preventDefault();
            
            var element = document.getElementById(event.target.dataset.target);
            
            element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        
        }); 
   }, []);

    const processData = data.allContentfulOrderProcess.edges;
    
    return(    
        <Layout location={location}>
            <Seo
                title={data.allContentfulPageData.edges[0].node.metaTitle}
                description={data.allContentfulPageData.edges[0].node.metaDescription.metaDescription}
            />
            <SectionHeros className="section-hero">
                <HeroBanner><StaticImage src="../images/inside-center-carport.jpg" alt="Inside Center Carport" /></HeroBanner>
                <HeroCaption>
                    <div className="container">		
                        <HeroCaptionGrid>
                            <HeroCaptionItem>
                                <SectionPageTitles className="h2" textAlign="center" color="#fff" mb="0" fontWeight="900">ORDERING PROCESS</SectionPageTitles>
                                <SectionDescriptions textAlign="center" color="#fff" fontStyle="italic"><p>Thank you for deciding to trust us for your building needs in Florida. We understand that purchasing a steel structure can be a big investment, and that’s why we’re here to  help guide you through every step of the process.</p></SectionDescriptions>
                                <ProcessStep>
                                    <ProcessStrip>
                                        <ProcessStartPoint>START</ProcessStartPoint>
                                        <StaticImage src="../images/process-step.png" alt="order process" />
                                    </ProcessStrip>
                                    <ProcessLabel>
                                        <ProcessItemLabel>
                                            <span className="process-point">
                                                <span className="circle"></span>
                                                <span className="text btn-scroll-into hvr-icon-down" data-target="section-1">Review <span className="icon hvr-icon"><LineArrowDownIcon /></span></span>
                                            </span>
                                        </ProcessItemLabel>
                                        <ProcessItemLabel>
                                            <span className="process-point">
                                                <span className="circle"></span>
                                                <span className="text btn-scroll-into hvr-icon-down" data-target="section-2">Confirm <span className="icon hvr-icon"><LineArrowDownIcon /></span></span>
                                            </span>
                                        </ProcessItemLabel>
                                        <ProcessItemLabel>
                                            <span className="process-point">
                                                <span className="circle"></span>
                                                <span className="text btn-scroll-into hvr-icon-down" data-target="section-3">Manufacture <span className="icon hvr-icon"><LineArrowDownIcon /></span></span>
                                            </span>
                                        </ProcessItemLabel>
                                        <ProcessItemLabel>
                                            <span className="process-point">
                                                <span className="circle"></span>
                                                <span className="text btn-scroll-into hvr-icon-down" data-target="section-4">Install <span className="icon hvr-icon"><LineArrowDownIcon /></span></span>
                                            </span>
                                        </ProcessItemLabel>
                                    </ProcessLabel>
                                </ProcessStep>
                            </HeroCaptionItem>
                        </HeroCaptionGrid>
                    </div>
                </HeroCaption>
                <HeroSearch>
                    <div className="container"><SearchBar className="center" /></div>
                </HeroSearch>
            </SectionHeros>

            <Section pt="80px" pb="80px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#fff" className="section-process">
                <div className="container">
                    <SectionGrid>
                      {
                        processData.map((item) => (
                          <SectionGridItem id={`section-${item.node.order}`}> 
                            <ProcessContent>
                                <ProcessText>
                                    <SectionTitle mb="0">{item.node.name}</SectionTitle>
                                    <SectionDescription>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: item.node.description.childMarkdownRemark.html,
                                        }}
                                      />
                                    </SectionDescription>
                                </ProcessText>
                            </ProcessContent>
                            <ProcessThumbnail>
                              <ThumbnailFigure><GatsbyImage image={item.node.image.gatsbyImageData} alt={item.node.name} /></ThumbnailFigure>
                            </ProcessThumbnail>
                          </SectionGridItem>
                        ))
                      }
                    </SectionGrid>
                    <SetionDotted>                        
                        <ListPointerBlue>
                            <ListItemPointerBlue>
                                <strong>Building Specs:</strong>
                                <p>Confirming correct dimensions, components, and colors. It is your responsibility to confirm all aspects of the contract, and to ensure that you understand what you will receive with the install.</p>
                            </ListItemPointerBlue>
                            <ListItemPointerBlue>
                                <strong>Balance Due:</strong>
                                <p>Confirming the price due at the time of installation, along with which types of payment we can accept. For buildings valued at $15,000 and above, we will require 50% of the balance to be paid at the time of scheduling.</p>
                            </ListItemPointerBlue>
                        </ListPointerBlue>
                    </SetionDotted>
                </div>
            </Section>
        
            <Section pt="80px" pb="80px" xpt="60px" xpb="60px" mpt="40px" mpb="40px"  bgColor="#fff" className="section-schedule">	
                <SectionImageBkg opacity="0.8"><StaticImage src="../images/bubble-pattern.jpg" alt="bubble pattern" /></SectionImageBkg>
                <div className="container">
                    <SectionTitle textAlign="center" mb="30px">Issues That Can Cause a Reschedule </SectionTitle>
                    <CardGrid className="card-grid">
                        <CardGridItem className="card-grid-item">
                            <CardIcon>
                                <CardIconBody>
                                    <CardIconFigure>
                                        <WeatherIcon />
                                        <CardIconTitle>Inclement Weather</CardIconTitle>
                                    </CardIconFigure>
                                    <CardIconContent>
                                        <CardIconTitle>Inclement Weather</CardIconTitle>
                                        <p>Unsuitable weather in your area, or inclement weather in an area through which our crews would have to travel in order to get to your install site.</p>
                                    </CardIconContent>
                                </CardIconBody>
                            </CardIcon>
                        </CardGridItem>

                        <CardGridItem className="card-grid-item">
                            <CardIcon>
                                <CardIconBody>                       
                                    <CardIconFigure>
                                        <MechanicalIcon />
                                        <CardIconTitle>Mechanical Issues</CardIconTitle>
                                    </CardIconFigure>
                                    <CardIconContent>
                                        <CardIconTitle>Mechanical Issues</CardIconTitle>
                                        <p>Malfunctions with installation trucks or trailers.  </p>
                                    </CardIconContent>
                                </CardIconBody>
                            </CardIcon>
                        </CardGridItem>

                        <CardGridItem className="card-grid-item">
                            <CardIcon>
                                <CardIconBody>
                                    <CardIconFigure>
                                        <FailedInstallationIcon />
                                        <CardIconTitle>Failed Installation Prep</CardIconTitle>
                                    </CardIconFigure>
                                    <CardIconContent>
                                        <CardIconTitle>Failed Installation Prep</CardIconTitle>
                                        <p>A customer not being ready for install (due to site issues or unavailability) that breaks apart a run. </p>
                                    </CardIconContent>
                                </CardIconBody>
                            </CardIcon>
                        </CardGridItem>

                        <CardGridItem className="card-grid-item">
                            <CardIcon>
                                <CardIconBody>
                                    <CardIconFigure>
                                        <CrewIcon />
                                        <CardIconTitle>Crew Shortage</CardIconTitle>
                                    </CardIconFigure>
                                    <CardIconContent>
                                        <CardIconTitle>Crew Shortage</CardIconTitle>
                                        <p>Installers can occasionally have physical injuries or other personal emergencies which can leave a crew short-handed. </p>
                                    </CardIconContent>
                                </CardIconBody>
                            </CardIcon>
                        </CardGridItem>
                    </CardGrid>
                </div>
            </Section>
        </Layout>
    )
}


export default Orderprocess

export const pageQuery = graphql`
  query OrderprocessPageQuery{
    allContentfulPageData(filter: {page: {eq: "Metal Buildings Insulation"}}) {
      edges {
        node {
          metaTitle
          metaDescription {
            metaDescription
          }
        }
      }
    }
    allContentfulOrderProcess(sort: {fields: order}) {
      edges {
        node {
          order
          name
          description {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(quality: 90)
          }
        }
      }
    }
  }
`